import * as Sentry from '@sentry/sveltekit'
import { dev } from '$app/environment'
import { PUBLIC_SENTRY_CLIENT_DSN } from '$env/static/public'


if (!dev) {
  Sentry.init({
    dsn: PUBLIC_SENTRY_CLIENT_DSN,
    integrations: [new Sentry.Replay()],
    tracesSampleRate: 1.0, // Capture 100% of the transactions. Adjust this value in production as necessary.
    replaysSessionSampleRate: 0.1, // This sets the Replay sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

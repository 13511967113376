import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [13,[2]],
		"/(app)/company": [27,[2]],
		"/(docs)/data-deletion": [38,[12]],
		"/(auth)/login": [33,[11]],
		"/(auth)/logout": [34,[11]],
		"/(app)/(admin)/manage-role": [14,[2]],
		"/(app)/(admin)/manage-startup": [15,[2,3]],
		"/(app)/(admin)/manage-startup/create": [16,[2,3]],
		"/(app)/(admin)/manage-startup/edit": [17,[2,3,4]],
		"/(app)/(admin)/manage-startup/edit/[id]": [18,[2,3,4]],
		"/(app)/(admin)/manage-startup/preview": [19,[2,3,5]],
		"/(app)/(admin)/manage-user": [20,[2,6]],
		"/(app)/(admin)/manage-vc": [21,[2,7]],
		"/(app)/(admin)/manage-vc/create": [22,[2,7]],
		"/(app)/(admin)/manage-vc/edit": [23,[2,7,8]],
		"/(app)/(admin)/manage-vc/edit/[id]": [24,[2,7,8]],
		"/(app)/(admin)/manage-vc/preview": [25,[2,7,9]],
		"/(app)/(admin)/manage-vc/preview/[id]": [26,[2,7,9]],
		"/(auth)/oauth": [35,[11]],
		"/(app)/portfolio": [28,[2]],
		"/(docs)/privacy": [39,[12]],
		"/(app)/profile": [29,[2]],
		"/(auth)/register": [36,[11]],
		"/(app)/startup": [30,[2,10]],
		"/(app)/startup/[id]": [31,[2,10]],
		"/(docs)/terms": [40,[12]],
		"/(app)/vc": [32,[2]],
		"/(auth)/verify": [37,[11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';